import { cancelProgramApi } from "@/api/parentsApi";
import { atomsWithMutation } from "jotai-tanstack-query";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { AsyncStorage } from "jotai/vanilla/utils/atomWithStorage";

import { UserLoginInfoInterface } from "@ts/interfaces/Parent/UserInterface";

const localStorageForAtom = createJSONStorage(() => localStorage);

export const [, cancelProgramAtom] = atomsWithMutation(() => ({
    mutationKey: ["cancelProgram"],
    mutationFn: async (data: {
        type: "addon" | "plan";
        licenseId?: number;
        itemId?: number;
        studentId: number;
        reasonId: number | null;
        cancellationType: "refund" | "non-refund";
    }) => {
        return (await cancelProgramApi(data)).data;
    }
}));

export const userLoginAtom = atomWithStorage<UserLoginInfoInterface>(
    "parentLoginInfo",
    {
        userName: "",
        rememberMe: false
    },
    localStorageForAtom as AsyncStorage<UserLoginInfoInterface>,
    { unstable_getOnInit: true }
);

// Defining debug info for the atoms created to be consumed by the Jotai DevTools
userLoginAtom.debugLabel = "userLoginAtom";
